import * as React from "react"
import Calander from "../components/calander"
import Layout from '../components/layout'
import Seo from "../components/seo"


const AllClassesPage = () => (
  <Layout  pageName="Classes">
    <Seo title="Home" />
    <Calander />
  </Layout>
)

export default AllClassesPage
